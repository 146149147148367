
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index1Rj5_ndKbk_45ppyWs38QiFMKZvVmcOEJbtQjRM3yWNsIMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/index.vue?macro=true";
import { default as index8QIxj6P_yx4A_J7vM404rIYCKq2AbiSnFI3_4_45rmBDgMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/index.vue?macro=true";
import { default as loginrn8v6G81tyAGEH3cN1qFeyQJsdL61yv07JLx18FkvdYMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/login.vue?macro=true";
import { default as entranceVoTk5pMddrvCddU3X01WP5Fqhp6uDoCXGsN5Rn8meGEMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/entrance.vue?macro=true";
import { default as dashboardmodXkbfenXiT6_Eo9ROA0zH2nP09h4DKZWRAkUEaaxkMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/dashboard.vue?macro=true";
import { default as index01XDuA1mhdHQ3FTuXBKpvxfZvU8ILXeQpZQDZJJX2gIMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/index.vue?macro=true";
import { default as _91id_932BmmIQ5xZw3zeAf8xeXb2SLiaYS_454ARzO2fCY0kF13IMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexYxg8jIrC0nYwmfxbScnVhOVhn4SLmwfi2ZgSqXz6nJ0Meta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as indexBJ_45pi8SbOmaPIUvphE7RDJFmKDhuTr7jTxQYJ_45t3z3QMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as colors6Qw9kqyZaMkhiFNuflOwRByiD4_39s1LfQYA_O3f7p4Meta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as _91id_93lxwq7y2RpY77TrTd4jFjaJ5_Rin1yQgysHOlMyiDDBoMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as index2HqkXGRQYMdSu6g_45LLqjZ1KW6NJ8XlfYPaYUXhMxyYEMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as favorite_45variantstJatJQiu7mzrzMsnlBZqwiCfXz9YLQElf5P2TS6pCaoMeta } from "/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/index.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/index.vue")
  },
  {
    name: "slug-login",
    path: "/:slug()/login",
    meta: loginrn8v6G81tyAGEH3cN1qFeyQJsdL61yv07JLx18FkvdYMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/login.vue")
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceVoTk5pMddrvCddU3X01WP5Fqhp6uDoCXGsN5Rn8meGEMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/entrance.vue")
  },
  {
    name: "slug-dashboard",
    path: "/:slug()/dashboard",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/dashboard.vue")
  },
  {
    name: "slug-customers",
    path: "/:slug()/customers",
    meta: index01XDuA1mhdHQ3FTuXBKpvxfZvU8ILXeQpZQDZJJX2gIMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/index.vue")
  },
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_932BmmIQ5xZw3zeAf8xeXb2SLiaYS_454ARzO2fCY0kF13IMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/catalog/logos/[id].vue")
  },
  {
    name: "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexYxg8jIrC0nYwmfxbScnVhOVhn4SLmwfi2ZgSqXz6nJ0Meta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/catalog/logos/index.vue")
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexBJ_45pi8SbOmaPIUvphE7RDJFmKDhuTr7jTxQYJ_45t3z3QMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/[id]/index.vue")
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colors6Qw9kqyZaMkhiFNuflOwRByiD4_39s1LfQYA_O3f7p4Meta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/[id]/colors.vue")
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93lxwq7y2RpY77TrTd4jFjaJ5_Rin1yQgysHOlMyiDDBoMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/[customer]/logos/[id].vue")
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: index2HqkXGRQYMdSu6g_45LLqjZ1KW6NJ8XlfYPaYUXhMxyYEMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/[customer]/logos/index.vue")
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantstJatJQiu7mzrzMsnlBZqwiCfXz9YLQElf5P2TS6pCaoMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.44.2/pages/[slug]/customers/[id]/favorite-variants.vue")
  }
]