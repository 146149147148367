import {
    faChevronDown,
    faAngleDown,
    faAngleUp,
    faHexagonImage,
    faAnglesUpDown,
    faAsterisk,
    faPencil,
    faArrowRightFromBracket,
    faCheck,
    faTimes,
    faCircleStar,
    faUsers,
    faPersonDigging,
    faCopy,
    faPalette,
    faCircleCheck,
    faBarsFilter,
    faCircle,
    faCartXmark,
    faCalculator,
    faUser,
    faShoppingBasket,
    faSwatchbook,
    faPersonToDoor,
    faX,
    faFile,
    type IconDefinition,
    faUserSecret,
} from "@fortawesome/pro-light-svg-icons";
import { faEllipsis } from "@fortawesome/pro-light-svg-icons/faEllipsis";

export const lightIcons: IconDefinition[] = [
    faChevronDown,
    faAngleDown,
    faAngleUp,
    faHexagonImage,
    faAnglesUpDown,
    faAsterisk,
    faPencil,
    faArrowRightFromBracket,
    faCheck,
    faTimes,
    faCircleStar,
    faUsers,
    faPersonDigging,
    faCopy,
    faPalette,
    faCircleCheck,
    faBarsFilter,
    faCircle,
    faCartXmark,
    faCalculator,
    faUser,
    faShoppingBasket,
    faSwatchbook,
    faPersonToDoor,
    faX,
    faFile,
    faEllipsis,
    faUserSecret,
];
